// Borders
$borders: (
    "0" "0" "transparent",
    "1" rem(1) variableValues($gray, "20"),
    "2" rem(2) variableValues($gray, "20"),
);

// Generate Rounded Class
@each $name, $value, $color in $borders {
    .border-#{$name} {
        border: #{$value} solid #{$color} !important;
    }
    .border-top-#{$name} {
        border-top: #{$value} solid #{$color} !important;
    }
    .border-bottom-#{$name} {
        border-bottom: #{$value} solid #{$color} !important;
    }
    .border-start-#{$name} {
        border-left: #{$value} solid #{$color} !important;
    }
    .border-end-#{$name} {
        border-right: #{$value} solid #{$color} !important;
    }
    .border-width-#{$name} {
        border-width: #{$value} !important;
    }
    .border-top-width-#{$name} {
        border-top-width: #{$value} !important;
    }
    .border-bottom-width-#{$name} {
        border-bottom-width: #{$value} !important;
    }
    .border-start-width-#{$name} {
        border-left-width: #{$value} !important;
    }
    .border-end-width-#{$name} {
        border-right-width: #{$value} !important; 
    }
    @include mq('sm', 'min') {
        .border-sm-#{$name} {
            border: #{$value} solid #{$color} !important;
        }
        .border-top-sm-#{$name} {
            border-top: #{$value} solid #{$color} !important;
        }
        .border-bottom-sm-#{$name} {
            border-bottom: #{$value} solid #{$color} !important;
        }
        .border-start-sm-#{$name} {
            border-left: #{$value} solid #{$color} !important;
        }
        .border-end-sm-#{$name} {
            border-right: #{$value} solid #{$color} !important;
        }
        .border-width-sm-#{$name} {
            border-width: #{$value} !important;
        }
        .border-top-width-sm-#{$name} {
            border-top-width: #{$value} !important;
        }
        .border-bottom-width-sm-#{$name} {
            border-bottom-width: #{$value} !important;
        }
        .border-start-width-sm-#{$name} {
            border-left-width: #{$value} !important;
        }
        .border-end-width-sm-#{$name} {
            border-right-width: #{$value} !important; 
        }
    }
}

.border-style-dashed {
    border-style: dashed !important;
}
